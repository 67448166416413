import React, {Component} from 'react';
import {Accordion, Button, Card, Col, Form, InputGroup, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";

class Contact extends Component {
    render() {
        return (
            <div>
                - Call
                - Whats App
                - Email
                - FAQ’s
                - Personal Query related to individual/corporate donations
            </div>
        )
    }
}

export default Contact;