import React, {Component} from 'react';
import {Accordion, Button, Card, Col, Form, InputGroup, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";

class Partners extends Component {
    render() {
        return (
            <div style={{width: '500px'}}>
                Partners
                Categories like Education, Children, Women Empowerment, Healthcare, livelihood,
                differently abled, Disaster relief, Community & rural development, Animal Welfare,
                Elderly Care, Human Rights, Environment, Hunger & Homelessness, Art & Culture,
                Sports,etc
                - Donor gets all major & minor details of NGO’s with trustee or owner name
                - Donor gets details of requirement by the NGO
                - Donor gets option to donate
            </div>
        )
    }
}

export default Partners;