import React, {Component} from 'react';
import {Accordion, Button, Card, Col, Form, InputGroup, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";

class AboutUs extends Component {
    render() {
        return (
            <div>
                Our Story
                - Team

                - Vision > Mission > Core Values/Philosophy
                - How we work?
                - Why Us/ What we offer?
                - Projects completed (Statistics) – Not Now
                - Awards & Accolades > Recognitions > Sponsors & Associates – Not Now
            </div>
        )
    }
}

export default AboutUs;