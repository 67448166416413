import React, {Component} from 'react';
import {Accordion, Button, Card, Col, Form, InputGroup, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";

class JoinJourney extends Component {
    render() {
        return (
            <div>
                - Become a Volunteer
                - Help in adding NGO’s
                - Provide service to grow the reach of NGO’s
                - Add NGO to benefit from the platform
            </div>
        )
    }
}

export default JoinJourney;